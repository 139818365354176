import React from 'react'
import Wrapper from '@components/Wrapper/Wrapper'
import '@styles/idd/main.scss'
import Home from './home'
import './fontCss.css'

const Index = () => {
  return (
    <Wrapper>
      <Home />
    </Wrapper>
  )
}

export default Index
